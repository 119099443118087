.TermsOverlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: -30px;
  left: -50px;
  bottom: -60px;
  right: -50px;
  z-index: 4;
}
.TermsModalContainer {
  background-color: #fff;
  position: fixed;

  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 800px;
  border-image: linear-gradient(
      to right,
      #65df46,
      #0094ff,
      #dc4eff,
      #ff4a1a,
      #ffb200
    )
    1 1;
  border-width: 3px;
  border-style: solid;
  padding: 40px 30px;
  max-height: 600px;
  height: fit-content;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    max-height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    bottom: auto;
    top: 0;
    bottom: 0;
    // height: calc(100% - 170px);
  }
  .ModalSection {
    text-align: center;
    .ModalTitle {
      color: #000000;
      font-weight: 600;
      font-size: 24px;
    }
    .ModalDesc {
      color: #173593;
      font-weight: 600;
      font-size: 20px;
      margin-top: 18px;
      background-color: #e6eff9;
      padding: 7px;
      border-radius: 8px;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
  .terms {
    margin-top: 27px;
    font-size: 16px;
    .termTitle {
      font-weight: 600;
      margin-bottom: 5px;
    }
    .termDesc {
      font-weight: 400;
      margin-bottom: 20px;
    }

    @media only screen and (max-width: 768px) {
      max-height: calc(100vh - 400px);
      overflow: auto;
    }
  }
  .ModalButtons {
    display: flex;
    gap: 17px;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
      padding-top: 20px;
      gap: 10px;
      font-size: 14px;
    }
    .cancelButton {
      color: #173593;
      border: 1px solid #173593;
      border-radius: 5px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      width: fit-content;
      cursor: pointer;
    }
    .acceptButton {
      color: #fff;
      cursor: pointer;
      background-color: #173593;
      border-radius: 5px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      width: fit-content;
    }
  }
}
