.box {
  max-height: 100%;

  &:nth-child(1) {
    @media (max-width: 992px) {
      // display: block;
    }

    @media (max-width: 768px) {
      // display: none;
    }
  }

  &:nth-child(2) {
    @media (max-width: 1584px) {
    }

    @media (max-width: 992px) {
    }

    @media (max-width: 768px) {
    }
  }
}
