.addSiteContainer {
  display: grid;
  grid-template-columns: repeat(4, [col] 1fr);
  grid-template-rows: repeat(1, [row] 45px);
  grid-gap: 8px;
  margin-top: 10px;
  @media screen and (max-width: 1727px) and (min-width: 768px) {
    grid-gap: 4px;
    grid-template-rows: repeat(1, [row] 43px);
  }
  @media screen and (max-width: 767px) and (min-width: 200px) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .addSiteDiv {
    display: flex;
    border: 1px solid var(--that_grey);
    border-radius: 8px;
    width: 216px;
    @media screen and (max-width: 1727px) and (min-width: 768px) {
      width: 151px;
    }
    @media screen and (max-width: 767px) and (min-width: 375px) {
      width: 147px;
    }
    @media screen and (max-width: 374px) and (min-width: 200px) {
      width: 100%;
    }

    .binSvg {
      width: 25px;
      display: flex;
      //margin-right: 5px;
      margin-left: auto;
      padding: 5px 10px 5px 0px;
    }

    &.active {
      color: var(--that_blue);
      background-color: var(--light_blue);
      border-color: var(--that_blue);

      &.assistant {
        color: var(--that_blue);
        background-color: var(--light_blue);
        border-color: var(--that_blue);
      }
      &.consigner {
        color: var(--that_purple);
        background-color: var(--light_purple);
        border-color: var(--that_purple);
      }
      &.seller {
        color: var(--that_green);
        background-color: var(--light_green);
        border-color: var(--that_green);
      }
    }
  }
}

.addServBox {
  width: 100%;
  @media screen and (max-width: 1727px) and (min-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) and (min-width: 200px) {
    width: 100%;
  }
  .addBox {
    display: block;
    color: var(--link_color);
    background-color: transparent;
    float: right;
    border: none;
    margin-top: 10px;
    font-size: 16px;
    &.consigner {
      color: var(--directory_text_purple);
    }
    &.seller {
      color: var(--directory_text_green);
    }
  }
}
.saveBtn {
  margin-top: 20px;
  margin-left: auto;
}
.checkmarkBoxContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, [col] 1fr);
  grid-template-rows: repeat(2, [row] 45px);
  grid-gap: 10px;
  @media screen and (max-width: 1727px) and (min-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) and (min-width: 200px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}
