.mainWrapper {
  padding: 10px;
  max-width: 1030px;
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    &.active {
      flex-direction: column;
    }
  }
  .quote {
    display: flex;
    flex-direction: column;

    //background-color: var(--light_purple);
    //border: 1px solid var(--that_purple);
    border-radius: 8px;

    padding: 10px;
    margin-bottom: 20px;
    color: var(--that_purple);
    font-size: 20px;
    text-align: right;
    font-weight: 600;
    /* @media screen and (max-width: 767px) {
            width: 300px;
        } */

    > div {
      margin-left: auto;
    }
    > span {
      font-size: 24px;
    }
  }

  .stats {
    display: flex;
    gap: 10px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .left {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 10px;

      //max-width: 320px;
      > .top {
        text-align: center;
      }
      > div {
        display: flex;
        flex-direction: column;
        padding: 10px;

        border-radius: 8px;

        color: white;
        &:nth-child(1) {
          display: flex;
          flex-direction: column;

          padding: 20px;

          background-color: var(--light_blue);
          color: var(--that_blue);
          .bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: auto;
            > div {
              &:nth-child(1) {
                font-size: 40px;
              }
              &:nth-child(2) {
                margin-top: auto;
              }
            }
          }
        }
      }
    }
    .right {
      flex: 2;
      > svg {
        width: 100%;
      }
    }
  }
  .addGoalBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 20px 0;

    border-bottom: 1px solid var(--that_grey);
  }
  //tabs
  .tabs {
    display: flex;
    gap: 10px;
    overflow: hidden;
    padding: 20px 0 10px 0;
    max-width: 320px;

    > button {
      float: left;
      border: 1px solid var(--that_grey);
      border-radius: 8px;
      outline: none;
      cursor: pointer;
      transition: 0.3s;

      width: 100px;
      height: 40px;

      font-size: 16px;
      text-align: center;

      > svg {
        padding-right: 5px;
      }

      &.pink {
        color: var(--text_color);
        &:hover {
          background-color: var(--light_red);
          border: 1px solid var(--that_red);
        }
        &.active {
          background-color: var(--that_red);
          border: 1px solid var(--that_red);

          color: white;
        }
      }
      &.green {
        color: var(--text_color);
        &:hover {
          background-color: var(--light_green);
          border: 1px solid var(--that_green);
        }
        &.active {
          background-color: var(--that_green);
          border: 1px solid var(--that_green);

          color: white;
        }
      }
      &.blue {
        color: var(--text_color);
        &:hover {
          background-color: var(--light_blue);
          border: 1px solid var(--that_blue);
        }
        &.active {
          background-color: var(--that_blue);
          border: 1px solid var(--that_blue);

          color: white;
        }
      }
    }

    /* Change background color of buttons on hover */
    > .tab button:hover {
      background-color: #ddd;
    }

    /* Create an active/current tablink class */
    > .tab button.active {
      background-color: #ccc;
    }

    /* Style the tab content */
    > .tabcontent {
      display: none;
      padding: 6px 12px;
      border: 1px solid #ccc;
      border-top: none;
    }
  }
}
