.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  // z-index: 999999;
  background: rgba(51, 51, 51, 0.4);

  .modal {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    width: 570px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border-image: linear-gradient(
        90deg,
        #65df46 0%,
        #0094ff 25%,
        #dc4eff 50%,
        #ff4a1a 75%,
        #ffb200 100%
      )
      1;
    border-width: 1px;
    border-style: solid;
    .closeModal {
      text-align: right;
      cursor: pointer;
    }

    .modal__wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .modalHeaderBlock {
        display: flex;
        align-items: flex-start;
        gap: 15px;

        .modalThumbnail {
          width: 100px;
          max-height: 100px;
          svg {
            transform: translate(20%, -20px);
            width: 70px;
          }
          img {
            width: 100px;
            height: 100px;
          }
        }

        .modalInfo {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 10px;

          h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: #333333;
          }
          .date {
            display: flex;
            color: var(--that_blue);
            gap: 10px;
            align-items: center;
          }
          div {
            display: flex;
            align-items: center;
            gap: 13px;

            span {
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #333333;
              display: flex;
              gap: 10px;
              flex-wrap: wrap;
              align-items: flex-start;
              &.date {
                font-weight: 600;
              }
            }
          }
        }
      }

      > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #333333;
      }

      .eventModalBtns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 13px;

        button {
          &:last-child {
            cursor: pointer;
            font-family: inherit;
            background: #173593;
            border-radius: 8px;
            padding: 10px 20px;
            color: #fff;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            outline: none;
            border: none;
            width: 200px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
