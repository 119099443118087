@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

.chatUserListContainer {
  font-family: 'Open Sans', sans-serif;
  // padding: 15px 20px 15px 35px;
  border: 2px solid #e6e6e6;
  position: relative;
  cursor: pointer;
  transition: 0.2s all;
  overflow: hidden;
  padding: 15px 0 15px 20px;
  border-radius: 8px !important;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    padding: 15px 0;
  }

  @media only screen and (min-width: 819px) and (max-width: 950px) {
    padding: 15px 0;
  }

  &:hover {
    border: 2px solid;
    border-image: linear-gradient(
        to right,
        #65df46,
        #0094ff,
        #dc4eff,
        #ff4a1a,
        #ffb200
      )
      1;
  }

  .chatUserListContainer_wrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    .isRead {
      @media (max-width: 768px) {
        margin-left: 10px;
      }

      @media only screen and (min-width: 819px) and (max-width: 950px) {
        margin-left: 10px;
      }
    }

    .avatar {
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }

    .chatUserInfo {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 100%;

      div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        p {
          margin: 0;
          padding: 0;
        }

        .messageDateTime {
          font-family: inherit;
          white-space: nowrap !important;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          // color: #363636;
          color: #b1b1b1 !important;
          margin-right: 20px;

          @media only screen and (min-width: 819px) and (max-width: 950px) {
            margin-right: 10px;
          }

          @media (max-width: 768px) {
            margin-right: 10px;
          }
        }
      }

      span {
        &:first-child {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #363636;
        }

        &:nth-child(2) {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #363636;
        }
      }

      .previewText {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #b1b1b1;

        width: 250px;
        overflow: hidden;

        div {
          overflow: hidden;
          // display: inline-block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          //
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 15px;

          p {
            overflow: hidden;
            // display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            margin: 0 !important;

            margin-block: 0 !important;
            margin-inline: 0 !important;

            a {
              color: inherit !important;
            }

            &:not(:first-of-type) {
              display: none; // fix it !!
            }
          }
        }
      }
    }
  }
}

.selectedUser {
  border: 2px solid;
  border-image: linear-gradient(
      to right,
      #65df46,
      #0094ff,
      #dc4eff,
      #ff4a1a,
      #ffb200
    )
    1;
}

.previewTextBold {
  font-weight: bold !important;
  color: #000 !important;
}

.lastMessage {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #b1b1b1;
  width: 250px;
  overflow: hidden;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
