// if desktop
.menuWrapper {
  display: block;
  position: fixed;

  width: calc(298px - 32px);
  height: calc(100% - 68px);
  padding: 8px 16px 0 16px;
  z-index: 10001;

  background-color: var(--clear_white);

  border-left: 1px solid var(--that_grey);
  border-right: 1px solid var(--that_grey);

  @media screen and (max-width: 1727px) and (min-width: 768px) {
    border-left: 1px solid var(--clear_white);
  }

  > .menu {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;

    > hr {
      width: 100%;
      border: none;
      border-bottom: 1px solid var(--that_grey);
    }

    > .collapseButton {
      height: 40px;

      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: flex-start;

      cursor: pointer;
      user-select: none;

      > span {
        min-width: 150px;
      }

      > svg,
      > span {
        margin-left: 18px;
        font-size: 16px;
        color: black;
      }
    }
  }

  // scrollbar
  overflow: overlay;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--that_grey);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
}

.menuWrapperCollapsed {
  position: fixed;

  padding: 8px 0 0 0;

  > .menu {
    align-items: center;

    > .navigation {
      width: 40px;
    }
  }

  .collapseButton {
    width: 40px;

    svg {
      transform: rotate(180deg);
    }
  }

  .wrapper > .babies {
    display: none;
  }

  .collapseButton,
  .title > a,
  .line {
    > svg {
      margin: auto !important;
    }
    > span {
      display: none;
    }
  }
}

.greyShadow {
  display: block;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  background-color: var(--gray_shadow);

  z-index: 1000;

  @keyframes animShow {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: animShow;
  animation-duration: 150ms;
}

.menuWrapper {
  transition: 300ms;

  span,
  label {
    white-space: nowrap;
  }

  // span,
  // label {
  // 	@keyframes animShow {
  // 		0% {
  // 			opacity: 0;
  // 		}
  // 		100% {
  // 			opacity: 1;
  // 		}
  // 	}

  // 	animation-name: animShow;
  // 	animation-duration: 300ms;
  // }

  svg {
    min-width: 22px;
  }
}

//
//
// anims

.item,
svg,
svg > path {
  transition: 300ms;
}
