.wrapper {
  display: flex;
  width: 100px;
  height: 100px;

  justify-content: center;
  align-items: center;

  border-radius: 50%;
  overflow: hidden;

  > img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;

    pointer-events: none;
    user-select: none;
  }

  box-sizing: border-box;
  // border: 2px solid red;

  position: relative;
  background-clip: padding-box;

  &:before {
    content: '';
    z-index: 1;
    border-radius: inherit;
    transition: 300ms;

    pointer-events: none;
    position: absolute;
    padding: 2px;
    inset: 0;

    background: var(--gradient);

    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: xor;
  }
}

.wrapper,
.wrapper > img {
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  opacity: 0;
  animation: 500ms fadein linear forwards;
}
