.modalProfile {
  width: 125px;
  height: 32px;
  background: #363636;
  z-index: 999;
  position: absolute;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  z-index: 9999;
  background: #363636;
  opacity: 0.4;
  border-radius: 5px;
  > p {
    color: white;
    text-transform: capitalize;
  }
}

.modalDirectory {
  width: 150px;
  height: 140px;
  background: #363636;
  position: absolute;
  bottom: 0px;
  left: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  z-index: 2;
  background: #363636;
  opacity: 0.9;
  border-radius: 8px;
  padding: 7px 35px 13px 10px;
  > p {
    color: white;
    text-transform: capitalize;
    padding-top: 15px;
  }
}
