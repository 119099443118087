.cardItem {
  width: 100%;
  display: inline-block;
  max-width: 340px;
  border-radius: 20px;
  position: relative;
  min-height: 530px;
}
.descContainer {
  display: block;
}
.cardItemFull {
  max-width: 90%;
  display: inline-block;
  border-radius: 20px;
  position: relative;
  background-color: #fff;
  z-index: 9;
  min-height: 560px;
  min-width: 1152px;

  @media only screen and(max-width:1600px) {
    min-width: 1000px;
  }
  @media only screen and(max-width:1300px) {
    min-height: 660px;
    min-width: auto !important;
  }
  .readMore {
    @media only screen and(max-width:768px) {
      position: inherit;
    }
  }
  @media only screen and(max-width:768px) {
    min-width: auto;
    min-height: auto;
    .descContainer {
      margin-bottom: 30px;
    }
  }
}

.readMore {
  position: absolute;
  bottom: 20px;
  margin: 0 auto;
  display: block;
  left: 0;
  right: 0;
  border: 2px solid transparent;
  cursor: pointer;
  border-image: linear-gradient(
      to right,
      #65df46,
      #0094ff,
      #dc4eff,
      #ff4a1a,
      #ffb200
    )
    1 1;
  border-radius: 16px;
  width: fit-content;
  padding: 10px 20px;
  margin: 0 auto;
}
.avatarContainer {
  display: flex;
  align-items: center;
  text-align: center;

  border-radius: 20px 20px 0 0;
  padding: 30px;
  .avatarBox {
    background-color: #d9d9d9;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    min-width: 80px;
    img {
      height: 80px;
      width: 80px;
      border-radius: 50%;
    }
  }

  .info {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    color: #fff;
    h2 {
      font-size: 20px;
      display: block;
    }
    p {
      font-size: 16px;
      padding: 0;
      margin: 0;
      display: block;
    }
  }
}
.descFull {
  font-size: 16px;
  font-weight: bold;
  padding: 37px 37px 20px 37px;
  position: relative;
  max-height: 100%;
  overflow: auto;
  max-height: calc(100vh - 380px);
  padding-bottom: 70px;

  .vector {
    height: 25px;
    width: 30px;
    position: absolute;
    top: 12px;
    left: 13px;
  }
}
.desc {
  font-size: 16px;
  font-weight: bold;
  padding: 37px 37px 20px 37px;
  position: relative;
  max-height: 240px;
  overflow: hidden;

  .vector {
    height: 25px;
    width: 30px;
    position: absolute;
    top: 12px;
    left: 13px;
  }
}
