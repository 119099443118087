.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  > .left {
    display: flex;
    flex-direction: row;
    > h3 {
      margin: 0;

      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }
  }

  > .right {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;

    > .buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      > * {
        width: 80px;
        height: 40px;

        &:last-child {
          margin-left: 10px;
          margin-right: 20px;

          &:before {
            background: var(--that_red);
          }
        }
      }

      > .imgLabel {
        display: flex;
        justify-content: center;
        align-items: center;

        user-select: none;
        color: var(--clear_white);
        background: var(--that_blue);
        border-radius: 8px;

        transition: 300ms;
        &:hover {
          opacity: 0.8;
        }

        > .imgInput {
          display: none;
        }
      }
    }

    > .imageHandler {
      position: relative;

      > .status {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        left: 0;
        right: 0;
        top: 100px;

        font-size: 12px;
        font-weight: 600;
        text-transform: lowercase;
        text-align: center;

        @keyframes fadein {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes fadeout {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }

        animation: fadein 100ms forwards;
        // animation: fadein 300ms forwards, fadeout 300ms forwards 3000ms;
      }

      > .image {
        width: 100px;
        height: 100px;

        @media screen and (min-width: 1728px) {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
}
