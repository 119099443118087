.specialtiesContainer {
  width: 100%;

  > .specialtiesBox {
    display: flex;
    width: 100%;
    margin-bottom: 10px;

    @media screen and (max-width: 767px) and (min-width: 200px) {
      justify-content: none;
      flex-wrap: wrap;
    }

    > .specialtiesLabel {
      margin-right: 10px;
      width: 422px;

      @media screen and (max-width: 1727px) and (min-width: 768px) {
        width: calc(46% - 5px);
      }

      @media screen and (max-width: 767px) and (min-width: 200px) {
        margin-right: 0;
        width: 270px;
      }

      &.descriptor {
        margin-right: 0;

        @media screen and (max-width: 767px) and (min-width: 200px) {
          margin-bottom: 15px;
          margin-top: 10px;
        }

        &.disabled {
          pointer-events: none;
          opacity: 50%;
        }
      }
    }
  }
}

.specialtiesContainer {
  width: 100%;

  > .specialtiesBox {
    display: flex;
    width: 100%;
    margin-bottom: 10px;

    @media screen and (max-width: 767px) and (min-width: 200px) {
      justify-content: none;
      flex-wrap: wrap;
    }

    > .specialtiesLabel {
      margin-right: 10px;
      width: 422px;

      @media screen and (max-width: 1727px) and (min-width: 768px) {
        width: calc(46% - 5px);
      }

      @media screen and (max-width: 767px) and (min-width: 200px) {
        margin-right: 0;
        width: 270px;
      }

      &.descriptor {
        margin-right: 0;

        @media screen and (max-width: 767px) and (min-width: 200px) {
          margin-bottom: 15px;
          margin-top: 10px;
        }

        &.disabled {
          pointer-events: none;
          opacity: 50%;
        }
      }
    }
  }
}
