.toggleDiv {
  display: flex;
  flex-direction: row;
  position: relative;
  @media screen and (max-width: 767px) and (min-width: 200px) {
    margin-top: 10px;
  }
  .toggleText {
    display: flex;
    position: absolute;
    margin-left: auto;
    right: 45px;
    > span {
      font-size: 14px;
      color: var(--text_color);
      font-weight: 400;
      @media screen and (max-width: 767px) and (min-width: 200px) {
        font-size: 12px;
      }
    }
  }
  .toggleSwitch {
    width: 33px;
    height: 18px;
    position: relative;
    display: flex;
    margin-bottom: 10px;
    margin-left: auto;
    overflow: hidden;
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      border: 2px solid #b1b1b1;
      -webkit-transition: 0.4s;
      border-radius: 34px;
      transition: 0.4s;
      &:before {
        //circle
        position: absolute;
        content: '';
        height: 8px;
        width: 8px;
        right: 16px;
        bottom: 1px;
        left: 1px;
        top: 1px;
        background-color: white;
        border: 2px solid #b1b1b1;
        border-radius: 50%;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
    &.active {
      //when toggle is on
      .slider {
        background-color: var(--that_blue);
        border: 2px solid var(--that_blue);
        &:before {
          //circle
          left: 16px;
          background-color: white;
          border: 2px solid white;
        }
      }
    }
  }
}
