.cardContainer {
  text-align: center;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  gap: 20px;

  margin-top: 30px;
  position: relative;
  > .sort {
    position: absolute;
    right: 0px;
    top: -77px;
    @media screen and (max-width: 767px) {
      top: -35px;
    }
    > select {
      border: 1px solid var(--that_grey);
      border-radius: 4px;
      font-size: 16px;
      padding: 5px;
    }
  }

  > h2 {
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  &.overdue {
    > div {
      //background-color: var(--light_red);
    }
  }
  &.upcoming {
    > div {
      //background-color: var(--light_blue);
    }
  }
  &.completed {
    > div {
      //background-color: var(--light_green);
    }
  }
  .active {
    border: 2px solid var(--that_blue);
    border-radius: 4px;
  }

  .top {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-end;
  }
  .bottom {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-end;
  }
}
