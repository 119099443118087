.chargesContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  > .chargesBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    .dollarSign {
      background-color: var(--that_lightgrey);
      border: 1px solid var(--that_grey);
      padding: 5px 5px 5px 5px;
      height: 26px;
      width: 20px;
      color: var(--text_color);
      display: flex;
      align-items: center;
      justify-content: center;
      &.disabled {
        opacity: 50%;
        pointer-events: none;
      }
      @media screen and (max-width: 374px) and (min-width: 200px) {
        width: 18px;
      }
    }
    > .chargesService {
      margin-right: 10px;
      border-radius: 8px;
      width: 428px;
      @media screen and (max-width: 1727px) and (min-width: 768px) {
        width: calc(46% - 5px);
      }
      @media screen and (max-width: 767px) and (min-width: 375px) {
        margin-right: 0;
        margin-top: 15px;
        margin-bottom: 10px;
        width: 270px;
        font-size: 16px;
      }
      @media screen and (max-width: 374px) and (min-width: 200px) {
        width: 270px;
        margin-bottom: 10px;
        margin-top: 20px;
      }
    }
    > .chargesPrice {
      height: 24px;
      padding: 6px;
      border: 1px solid var(--that_grey);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      //margin-bottom: 10px;
      width: 180px;
      border-left: none;
      &.disabled {
        opacity: 50%;
        pointer-events: none;
      }
      &:hover {
        border: 1px solid var(--that_blue);
        &.consigner {
          border: 1px solid var(--directory_text_purple);
        }
      }
      @media screen and (max-width: 1727px) and (min-width: 768px) {
        font-size: 12px;
        width: 110px;
      }
      @media screen and (max-width: 767px) and (min-width: 375px) {
        font-size: 16px;
        width: 85px;
      }
      @media screen and (max-width: 374px) and (min-width: 200px) {
        font-size: 12px;
        width: 85px;
      }
      input {
        border: none;
        width: 88px;
        font-size: 16px;
        &:focus {
          border: 1px solid white;
        }
        &:active {
          border: 1px solid white;
        }
      }
    }
    > .chargesItem {
      //margin-bottom: 10px;
      width: 190px;
      &.disabled {
        opacity: 50%;
        pointer-events: none;
      }
      @media screen and (max-width: 1727px) and (min-width: 768px) {
        font-size: 12px;
        width: 128px;
      }
      @media screen and (max-width: 767px) and (min-width: 375px) {
        width: 140px;
        font-size: 12px;
      }
      @media screen and (max-width: 374px) and (min-width: 200px) {
        width: 142px;
        font-size: 12px;
      }
    }
  }
}
