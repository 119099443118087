.removeBtnBox {
  display: flex;
  width: 30px;
  align-self: center;
  margin-left: 10px;
  @media screen and (max-width: 767px) and (min-width: 200px) {
    padding-bottom: 7px;
    padding-top: 0;
    margin-left: 5px;
  }
  &.charges {
    padding-top: 8px;
    margin-top: 0;
  }
  .icon {
    display: flex;
    width: 30px;
    height: 30px;
  }
  &.charges {
    margin-top: -4px;
  }
}
