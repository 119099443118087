.container {
  display: flex;
  flex-direction: column;
  border-bottom: 10px solid var(--gradient);
  padding: 0;
  margin: 0;
  width: 100%;

  .first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    background-color: var(--that_blue);
    background-image: url('../../assets/svg/splash/background_white.svg');
    background-repeat: no-repeat;
    color: white;

    width: 100%;
    padding: 140px 0 70px 0;

    box-sizing: border-box;
    position: relative;
    background-clip: padding-box;

    @media screen and (max-width: 767px) and (min-width: 524px) {
      padding: 100px 0;
    }
    @media screen and (max-width: 523px) and (min-width: 200px) {
      padding: 70px 0;
    }

    &:before {
      content: '';
      z-index: 1;
      transition: 300ms;

      pointer-events: none;
      position: absolute;
      padding-bottom: 10px;
      inset: 0;

      background: var(--gradient);

      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: xor;
    }

    > h1 {
      font-size: 3rem;
      line-height: 40px;
      @media screen and (max-width: 767px) and (min-width: 524px) {
        font-size: 2rem;
      }
      @media screen and (max-width: 523px) and (min-width: 200px) {
        font-size: 1.5rem;
      }
    }
    > .center {
      display: flex;
      flex: 1;

      height: 100%;
      max-width: 85%;

      align-items: center;
      &.box {
        padding: 20px 0px;
        min-width: 85%;
        gap: 10px;

        justify-content: center;
        align-items: center;
        text-align: center;

        box-sizing: border-box;
        position: relative;
        background-clip: padding-box;

        &:before {
          content: '';
          display: flex;
          z-index: 1;
          border-radius: 8px;
          transition: 300ms;

          pointer-events: none;
          position: absolute;
          padding: 2px;
          inset: 0;

          background: var(--gradient);

          mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          mask-composite: exclude;
          -webkit-mask-composite: xor;
        }
        > img {
          width: 50%;
        }
        > h1 {
          font-size: 3rem;
          margin-top: -10px;
          @media screen and (max-width: 767px) and (min-width: 524px) {
            font-size: 2rem;
            margin-top: -3px;
          }
          @media screen and (max-width: 523px) and (min-width: 200px) {
            font-size: 1.5rem;
            margin-top: -5px;
          }
        }
      }

      > p {
        text-align: center;
        font-size: 1.5rem;
        @media screen and (max-width: 523px) and (min-width: 200px) {
          font-size: 1rem;
        }
      }
    }

    > button {
      display: flex;

      font-size: 1.5rem;
      padding: 25px;

      background-color: var(--clear_white);
      color: var(--that_blue);
      border: solid 1px var(--clear_white);

      justify-content: center;
      align-items: center;
      @media screen and (max-width: 523px) and (min-width: 200px) {
        font-size: 1rem;
        padding: 20px;
      }
    }
  }
  > .second {
    display: flex;
    flex-direction: column;
    gap: 20px;

    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 70px 0;
    > .topTxtDiv {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 988px) {
        flex-direction: column;
      }
      > div {
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      > .colorDiv {
        display: flex;
        border-radius: 8px;

        background-color: var(--light_green);
        color: var(--directory_text_green);
      }
      > div > h1 {
        font-size: 2rem;
        padding: 25px;
      }
    }
    .center {
      max-width: 85%;
      justify-content: center;
      > p {
        text-align: center;
        font-size: 1.5rem;
        margin: 0px;
        @media screen and (max-width: 523px) and (min-width: 200px) {
          font-size: 1rem;
        }
      }
    }
    > button {
      display: flex;

      font-size: 1.5rem;
      padding: 25px;

      background-color: var(--clear_white);
      color: var(--that_blue);
      border: solid 1px var(--that_blue);

      justify-content: center;
      align-items: center;
      @media screen and (max-width: 523px) and (min-width: 200px) {
        font-size: 1rem;
        padding: 20px;
      }
    }
  }
  .middle {
    display: flex;
    flex-direction: row;
    gap: 50px;

    justify-content: center;

    width: 100%;
    padding: 50px 0px;
    border: solid 1px var(--light_grey);
    @media screen and (max-width: 988px) {
      flex-direction: column;
    }
    > .card {
      display: flex;
      flex-direction: column;
      gap: 27px;
      align-items: center;

      width: 100%;
      > div > img {
        width: 100%;
        height: 100%;
      }
      .cardTxt {
        justify-content: center;
        text-align: center;
        > h1 {
          font-size: 2rempx;
        }
        > .topTxt {
          padding: 10px;
          border-radius: 8px;
          > h1 {
            font-size: 2rem;
          }
          &.blue {
            background-color: var(--light_blue);
            color: var(--directory_text_blue);
            margin: 10px;
          }
          &.purple {
            background-color: var(--light_purple);
            color: var(--directory_text_purple);
            margin: 10px;
          }
          &.yellow {
            background-color: var(--light_yellow);
            color: var(--that_orange);
            margin: 10px;
          }
        }
        > p {
          font-size: 24px;
        }
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;

    text-align: center;

    width: 100%;
    padding: 155px 0 50px 0;

    background-image: url('../../assets/svg/splash/background_white.svg');
    background-repeat: no-repeat;
    @media screen and (max-width: 767px) and (min-width: 524px) {
      padding: 70px 0;
    }
    @media screen and (max-width: 523px) and (min-width: 200px) {
      padding: 50px 0;
    }
    > h1 {
      font-size: 3rem;
      background: var(--gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 20px 0;
      @media screen and (max-width: 767px) and (min-width: 524px) {
        font-size: 2rem;
      }
      @media screen and (max-width: 523px) and (min-width: 200px) {
        font-size: 1.5rem;
      }
    }
    > p {
      font-size: 32px;
      @media screen and (max-width: 523px) and (min-width: 200px) {
        font-size: 1.5rem;
      }
    }
    > .iconsContainer {
      display: flex;
      flex-direction: row;
      align-self: center;
      align-items: center;
      justify-content: center;
      gap: 45px;
      @media screen and (max-width: 900px) and (min-width: 768px) {
        gap: 30px;
      }
      @media screen and (max-width: 767px) and (min-width: 520px) {
        gap: 20px;
      }
      @media screen and (max-width: 519px) and (min-width: 200px) {
        gap: 20px;
      }
      @media screen and (max-width: 400px) {
        flex-direction: column;
      }
      > .iconsDivs {
        display: flex;
        gap: 45px;
        @media screen and (max-width: 900px) and (min-width: 768px) {
          gap: 30px;
        }
        @media screen and (max-width: 767px) and (min-width: 520px) {
          gap: 20px;
        }
        @media screen and (max-width: 519px) and (min-width: 200px) {
          gap: 20px;
        }
        > svg {
          cursor: pointer;
          @media screen and (max-width: 900px) and (min-width: 768px) {
            width: 80px;
            height: 80px;
          }
          @media screen and (max-width: 767px) and (min-width: 520px) {
            width: 60px;
            height: 60px;
          }
          @media screen and (max-width: 519px) and (min-width: 200px) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //text-align: center;
    gap: 50px;

    padding: 50px 20px;

    background-color: var(--that_blue);
    color: #fff;
    font-size: 24px;
    @media screen and (max-width: 767px) and (min-width: 200px) {
      gap: 30px;
      font-size: 16px;
    }
    @media screen and (max-width: 523px) and (min-width: 200px) {
      //justify-content: space-between;
    }

    > img {
      max-width: 50%;
    }
    > p {
      margin: 0;
      text-align: center;
    }
    > .footerTop {
      display: flex;
      flex-direction: row;

      justify-content: center;
      align-items: center;
      gap: 100px;
      @media screen and (max-width: 988px) {
        gap: 50px;
      }
      > div {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    > .footerMiddle {
      display: flex;
      flex-direction: row;
      gap: 100px;
      @media screen and (max-width: 988px) {
        gap: 50px;
      }
      > div {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
