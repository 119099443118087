.div {
  margin-top: 20px;
}
.addServBox {
  width: 100%;
  margin-bottom: 30px;
  @media screen and (max-width: 1727px) and (min-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) and (min-width: 200px) {
    width: 100%;
  }
  .addBox {
    display: block;
    color: var(--link_color);
    background-color: transparent;
    float: right;
    border: none;
    margin-top: 10px;
    font-size: 16px;
    &.consigner {
      color: var(--directory_text_purple);
    }
    &.seller {
      color: var(--directory_text_green);
    }
  }
}
