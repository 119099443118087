.slider-conteiner .slick-prev::before {
  border-radius: 50%;
  content: '';
  background-image: url('../../assets/picture/leftarrow.jpg');
  background-size: contain;
  display: block;
  width: 40px;
  height: 40px;
}
.slider-conteiner .slick-dots {
  bottom: -38px;
}
.slider-conteiner .slick-next {
  right: -3px;
}
.slider-conteiner .slick-next::before {
  border-radius: 50%;
  content: '';
  background-image: url('../../assets/picture/rightarrow.jpg');
  background-size: contain;
  display: block;
  width: 40px;
  height: 40px;
}
.overlayMobile {
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 2em;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
}

.slider-conteiner .slick-slider {
  max-width: 1280px !important;
  position: relative;
}
.avatarContainer {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #0094ff;
  border-radius: 20px 20px 0 0;
  padding: 30px;
}
.avatarContainer .info {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;
}
.avatarContainer .info h2 {
  font-size: 20px;
  display: block;
}
.avatarContainer .info p {
  font-size: 16px;
  padding: 0;
  margin: 0;
  display: block;
}
.avatar-box {
  background-color: #d9d9d9;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
.item {
  width: 100%;
  display: inline-block;
  border: 1px solid #0094ff;
  max-width: 340px;
  border-radius: 20px;
}
.slider-conteiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 1280px;
  margin: 200px auto 50px auto;
}
.desc {
  font-size: 16px;
  font-weight: bold;
  padding: 37px;
}
@media screen and (max-width: 1684px) {
  .slider-conteiner .slick-prev {
    left: -65px;
  }
  .slider-conteiner .slick-next {
    right: -25px;
  }
}
@media only screen and (max-width: 1300px) {
  .slider-conteiner .slick-slider {
    max-width: 930px !important;
  }
  .slider-conteiner .slick-prev {
    left: -65px;
  }
  .slider-conteiner .slick-next {
    right: -24px;
  }
  .slider-conteiner .slick-slider {
    max-width: 740px !important;
  }
}
.headerTitle {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 60px;
}
@media only screen and (max-width: 768px) {
  .slider-conteiner .slick-slider {
    max-width: 350px !important;
  }
  .headerTitle {
    font-size: 20px !important;
    margin-bottom: 30px;
  }

  .slider-conteiner .slick-arrow {
    border-radius: 50%;
    margin-top: 330px;
    z-index: 999;
    height: 30px;
    width: 30px;
    margin-top: 290px;
  }
  .slider-conteiner .slick-prev {
    left: 60px;
  }
  .slider-conteiner .slick-next {
    right: 80px;
  }
  .slider-conteiner .slick-dots li {
    margin: -5px;
  }
  .slider-conteiner .slick-next::before {
    width: 20px;
    background-repeat: no-repeat;
    height: 20px;
  }
  .slider-conteiner .slick-prev::before {
    width: 20px;
    background-repeat: no-repeat;
    height: 20px;
  }
  .slider-conteiner {
    margin: 100px auto 50px auto;
  }
}
@media only screen and (max-width: 1600px) {
  .slider-conteiner {
    max-width: 1140px;
  }
}
