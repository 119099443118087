.wrapper {
  width: 100% !important;

  > .header {
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;

    > .title {
      margin-bottom: 10px;
      @media screen and (max-width: 873px) and (min-width: 200px) {
        text-align: center;
      }
    }
    > .results {
      color: var(--that_grey);
    }
  }

  > .body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    @media screen and (max-width: 873px) and (min-width: 200px) {
      justify-content: center;
    }
  }

  > .loadingDiv {
    width: 100%;
    margin: 10px;
    text-align: left;
    font-size: 20px;
  }
}
