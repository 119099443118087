.checkmarkBox {
  border: 1px solid var(--that_grey);
  border-radius: 8px;
  width: 100%;
  height: 30px;
  padding: 5px;

  @media screen and (min-width: 768px) {
    width: calc(100% - 10px);
  }
  @media screen and (max-width: 768px) {
    width: 44%;
  }
  &.other {
    display: none;
  }
  &.active {
    background-color: var(--light_blue);
    border-color: var(--that_blue);
    &.consigner {
      color: var(--that_purple);
      background-color: var(--light_purple);
      border-color: var(--that_purple);
    }
    &.seller {
      color: var(--that_green);
      background-color: var(--light_green);
      border-color: var(--that_green);
    }
    &.assistant {
      color: var(--that_blue);
    }
  }
  &.hidden {
    display: none;
  }
  &.pref {
    width: 100%;
  }
  > .checkmarkLabel {
    display: flex;
    font-size: 1rem;
    padding: 3px 5px 5px 5px;
    cursor: pointer;
    > input {
      cursor: pointer;
    }
    > .checkmark {
      display: inline-block;
      margin-left: auto;
      width: 20px;
      height: 20px;
      accent-color: var(--that_blue);
      &.consigner {
        accent-color: var(--that_purple);
      }
      &.seller {
        accent-color: var(--that_green);
      }
    }
  }
}
