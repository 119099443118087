$small-screen: 768px;
$max-screen: 1200px;

.chatUsers {
  padding: 20px 30px;
  height: 80vh;

  @media (max-width: 768px) {
    padding: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #767778;
  }

  &::-webkit-scrollbar-track {
    background-color: #ccc;
    margin: 5px 0;
  }

  &::scrollbar-track {
    background-color: #1c1d1e;
  }

  &::-webkit-scrollbar {
    width: 10px !important;
  }

  .chatUsersTitle {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    @media (max-width: 1200px) {
      margin-left: 15px;
    }

    @media only screen and (min-width: 819px) and (max-width: 950px) {
      margin-left: 10px;
    }

    @media (max-width: 768px) {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 10px;
    }

    .chatUsersCount {
      width: 30px;
      height: 27px;
      background: #e9faf3;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #009566;
      }
    }

    .chatGroup {
      background-color: #e2efff;
      border-radius: 8px;
      cursor: pointer;

      svg {
        padding: 8px 6px;
      }
    }
  }

  .chatUsersList {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .noMessagesBlock {
      font-family: 'Open Sans', sans-serif;
      padding: 40px 50px;
      text-align: center;

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        display: block;
        margin-bottom: 19px;
      }

      button {
        background: #e6eff9;
        padding: 9px 21px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #173593;
        border-radius: 8px;
        border: none;
        cursor: pointer;
      }
    }
  }
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 100px;

  > svg {
    animation: spin 2s linear infinite;

    width: 50px;
    height: 50px;

    > path {
      color: grey;
    }
  }

  > span {
    color: grey;
    margin-top: 10px;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (min-width: $small-screen) and (max-width: $max-screen) {
  .chatUsers {
    padding: 10px 10px 10px 0;
  }
}