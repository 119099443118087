@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');

.groupMembers__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background: rgba(51, 51, 51, 0.4);

  .modal {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    border-radius: 8px;
    width: 600px;

    background-color: #fff;
    // padding: 30px 34px;
    padding: 20px 40px 50px 40px;

    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 15px;
    &::-webkit-scrollbar {
      width: 5px;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: var(--that_grey);
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #c1c1c1;
    }

    .closeModalBtn {
      cursor: pointer;
      align-self: flex-end;
    }

    .groupMembersHeader {
      display: flex;
      align-items: center;
      gap: 23px;

      h1 {
        color: #000000;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        display: flex;
      }
    }

    .membersWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      padding: 12px 0;

      border: 1px solid #e6e6e6;
      border-radius: 8px;

      // max-height: 80vh;
      // overflow-y: auto;

    

      .membersWrapperItem {
        display: flex;
        align-items: center;
        transition: 0.3s all;
        cursor: pointer;
        padding: 5px 0 5px 20px;
        justify-content: space-between;

        &:hover {
          background: #e6eff9;
        }

        .left {
          display: flex;
          gap: 15px;
          align-items: center;

          .wrapperItemPhoto {
            width: 30px;

            img {
              width: 100%;
              height: 30px;
              border-radius: 50%;
            }
          }

          .wrapperItemInfo {
            display: flex;
            align-items: center;
            gap: 2px;

            span {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;

              &:first-child {
                color: #000000;
                font-weight: 700;
                text-transform: capitalize;
              }

              &:last-child {
                color: #363636;
              }
            }
          }
        }

        .right {
          cursor: pointer;
        }
      }
    }
  }
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  //   height: 70vh; // fix -it

  > svg {
    animation: spin 2s linear infinite;

    width: 50px;
    height: 50px;

    > path {
      color: grey;
    }
  }

  > span {
    color: grey;
    margin-top: 10px;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
