.calendar {
  border: 1px solid #173593;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px 20px;
  position: relative;
  max-width: 195px;
  @media only screen and (max-width: 768px) {
    max-width: 160px;
  }
  .calendarTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #173593;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  .calendarItems {
    position: absolute;
    top: 110%;
    left: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    // height: 50px;
    z-index: 99;
    width: 100%;

    border: 1px solid #173593;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    @media only screen and (max-width: 768px) {
      top: -180px;
    }

    .calendarSection {
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      cursor: pointer;
      padding-left: 20px;
      a {
        display: flex;
        gap: 5px;
        align-items: center;
        margin-left: 17px;
      }
      &:hover {
        background-color: #f6f1e9;
      }
    }
  }
}
