.padlockContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  > .txt {
    font-size: 16px;
    color: var(--text_color);
    font-weight: 300;
    padding: 5px;
    @media screen and (max-width: 767px) and (min-width: 200px) {
      font-size: 12px;
    }
  }
  &.disabled {
    opacity: 0.5;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
  }
  .iconName {
    text-align: right;
    float: right;
  }
  .icon {
    float: right;
    height: 20px;
    padding: 5px;
  }
}
