.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  > h1 {
    span {
      // padding: 5px;
      font-size: 24px;
      font-weight: 600;
      color: var(--that_purple);
    }
  }
  .cardContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 50px;

    .padlock {
      position: absolute;
      right: 0;
      top: 0;
      gap: 10px;
      border: none;
    }

    &.disabled {
      opacity: 50%;
      pointer-events: none;
    }
    @media screen and (max-width: 1400px) and (min-width: 988px) {
      min-width: 800px;
    }
    @media screen and (max-width: 987px) and (min-width: 787px) {
      min-width: 700px;
    }
    @media screen and (max-width: 786px) and (min-width: 580px) {
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      min-width: 500px;
    }
    @media screen and (max-width: 579px) and (min-width: 460px) {
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      min-width: 400px;
    }
    @media screen and (max-width: 459px) {
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      width: 320px;
    }
    &.profile {
      background-color: var(--light_green);
      &:hover {
        border: solid 1px var(--that_green);
      }
    }
    &.events {
      background-color: var(--light_purple);
      &:hover {
        border: solid 1px var(--that_purple);
      }
    }
    &.directory {
      background-color: var(--light_orange);
      &:hover {
        border: solid 1px var(--that_orange);
      }
    }
    &.listings {
      background-color: var(--light_blue);
      &:hover {
        border: solid 1px var(--that_blue);
      }
    }
    &.video {
      background-color: var(--light_pink);
      &:hover {
        border: solid 1px var(--that_pink);
      }
    }

    .left {
      flex: 1;
      display: contents;
      align-items: center;
      > svg {
        width: 70px;
        height: 60px;
      }
      &.profile {
        > svg > path {
          fill: var(--that_green);
        }
      }
      &.events {
        > svg > path {
          fill: var(--that_purple);
        }
      }
      &.directory {
        > svg > path {
          fill: var(--that_orange);
        }
      }
      &.listings {
        > svg > path {
          fill: var(--that_blue);
        }
      }
    }
    .middle {
      flex: 3;
      gap: 20px;
      &.profile {
        color: var(--that_green);
      }
      &.events {
        color: var(--that_purple);
      }
      &.directory {
        color: var(--that_orange);
      }
      &.listings {
        color: var(--that_blue);
      }
      &.video {
        color: var(--that_pink);
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      > div {
        @media screen and (max-width: 786px) {
          text-align: center;
        }
      }
      .videoContainer {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
        > iframe {
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          position: absolute;
        }
      }
    }
    .right {
      display: flex;
      flex: 1;
      > button {
        background-color: transparent;
        width: 150px;
        margin-left: auto;
        &.profile {
          color: var(--that_green);
          border: solid 1px var(--that_green);
        }
        &.events {
          color: var(--that_purple);
          border: solid 1px var(--that_purple);
        }
        &.directory {
          color: var(--that_orange);
          border: solid 1px var(--that_orange);
        }
        &.listings {
          color: var(--that_blue);
          border: solid 1px var(--that_blue);
        }
      }
    }
  }
}
