.wrapper {
  width: 22px;
  height: 22px;

  svg {
    width: 20px;
    height: 20px;
    margin: 1px;
    color: var(--that_grey);
  }

  display: flex;

  // position: relative;

  * {
    user-select: none;
  }
  .icon {
    margin: 0 5px;
  }

  > .tooltip {
    display: flex;
    flex-direction: column;

    padding: 15px;
    width: 50vw;
    max-width: 600px;
    box-sizing: border-box;

    margin-top: 28px;
    position: absolute;
    z-index: 100;

    > .title,
    > .body {
      color: var(--text_color);
      font-family: Open Sans;
      letter-spacing: 0em;
      text-align: left;
    }

    > .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;

      margin-bottom: 10px;
    }

    > .title:empty {
      display: none;
    }

    > .body {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      > *:first-child {
        padding-top: 0;
        margin-top: 0;
      }

      > * {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      > *:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  // gradient border only
  > .tooltip {
    background: var(--clear_white);
    background-clip: padding-box;

    border: 2px solid transparent;
    border-radius: 10px;

    &:before {
      content: '';
      border-radius: inherit;

      pointer-events: none;
      position: absolute;
      padding: 2px;
      inset: 0;
      border-radius: 8px;

      background: var(--gradient);

      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: xor;
    }
  }

  // anime
  > .tooltip {
    @keyframes fadeIn {
      from {
        left: 0;
        opacity: 0;
      }

      to {
        left: 30px;
        opacity: 1;
      }
    }

    @keyframes fadeOut {
      from {
        left: 30px;
        opacity: 1;
      }

      to {
        left: 200px;
        opacity: 0;
      }
    }

    &.on {
      animation: fadeIn 300ms forwards;
    }

    &.off {
      animation: fadeOut 300ms forwards;
      left: 200px;
      opacity: 0;

      pointer-events: none;
    }
  }
}

.clickToClose {
  // background-color: red;
  background-color: transparent;
  position: fixed;
  z-index: 20;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
