.chatContainer {
  display: flex;
  max-width: 1728px;
  height: calc(100vh - 100px);
  width: 100%;
  background-color: #f8f8f8;

  .innerContainer {
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 100%;

    @media (max-width: 768px) {
      display: block;
      margin: 0 !important;
    }

    // @media only screen and (min-width: 374px) and (max-width: 668px) {}
  }
}
