.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-start;

  box-sizing: inherit;

  max-width: 1728px;
  min-width: 320px;
  height: calc(100% - 60px);
  margin: auto;

  //
  //
  //

  > .body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > .menu,
  > .body {
    transition: 200ms;
    padding-top: 60px;
  }

  //
  //
  //

  // desktop
  // @media screen and (min-width: 768px) {
  // 	width: 0;
  // }

  // mobile
  @media screen and (max-width: 767px) {
    width: 100%;

    > .menu,
    > .body {
      padding-top: 0;
    }
  }

  @media screen and (min-width: 1920px) {
    max-width: none;
  }

  @media screen and (min-width: 1728px) {
    max-width: none;
  }
}

.help {
  position: fixed;
  // bottom: 60px;
  bottom: 14px;

  right: 60px;
  border-radius: 50px;

  z-index: 1000;

  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;
  background-color: var(--that_blue);

  span {
    font-weight: 600;
    color: var(--clear_white);
  }

  &.mobile {
    right: 20px;
    bottom: 140px;

    @media screen and (max-width: 768px) {
      bottom: 70px;
    }
  }

  transition: 300ms;

  &:hover {
    opacity: 0.9;
  }

  @media screen and (min-width: 768px) {
    right: 20px;
  }
}
