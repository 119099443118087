.wrapper {
  border: solid 1px #e9eff0;
  border-radius: 8px;

  padding-bottom: 20px;
  background-color: var(--clear_white);

  width: 360px;

  @media screen and (max-width: 380px) {
    width: 320px;
  }

  > .block {
    width: 100%;

    > .line {
      display: flex;
      flex-direction: row;
      align-items: center;

      width: 100%;

      > .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding: 20px;
        width: 100%;
        overflow: hidden;

        border-radius: 8px 8px 0 0;

        > .innerDiv {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;

          > .picture {
            padding-right: 12px;
          }

          > .innerRow {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            > .topLine {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              padding: 0px;
              gap: 5px;

              height: 65px;
              width: 210px;

              > h2,
              span {
                width: 210px;
                font-size: 18px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                @media screen and (max-width: 380px) {
                  width: 180px;
                }
              }

              > .name {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 5px;
              }
            }

            > .botLine {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0px;
              gap: 10px;
              font-size: 16px;

              height: 43px;
              > span {
                text-transform: capitalize;
              }
              > .typeTxt {
                border-radius: 8px;
                padding: 4px 10px;
                gap: 10px;
                &.assistant {
                  color: var(--directory_text_blue);
                  background: var(--directory_text_background_blue);
                }
                &.seller {
                  color: var(--directory_text_green);
                  background: var(--directory_text_background_green);
                }
                &.consigner {
                  color: var(--directory_text_purple);
                  background: var(--directory_text_background_purple);
                }
                > span {
                  text-transform: capitalize;
                }
              }
            }
          }
        }

        &.assistant {
          background: var(--light_blue);
        }
        &.seller {
          background: var(--light_green);
        }
        &.consigner {
          background: var(--light_purple);
        }
      }

      > .leftLine {
        display: flex;
        box-sizing: border-box;
        flex-direction: row;
        flex: 1;
        justify-content: center;
        align-items: center;
        gap: 10px;

        height: 42px;
        border-bottom: 1px solid #e9eff0;
        border-top: 1px solid #e9eff0;

        font-size: 14px;
        font-weight: 400;
      }

      > .rightLine {
        display: flex;
        box-sizing: border-box;
        flex-direction: row;
        flex: 1;
        justify-content: center;
        align-items: center;
        gap: 10px;

        height: 42px;

        border: 1px solid #e9eff0;
        border-right: none;
        > .tagsPool {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--directory_text_purple);
          font-size: 14px;
          font-weight: 400;
          width: 100%;
          > span {
            margin: 5px;
            text-transform: capitalize;
          }
          > div {
            display: flex;
            width: 100%;
            justify-content: center;
            text-align: center;
            font-size: 14px;
            > svg {
              margin-right: 5px;
            }
            > span {
              font-size: 14px;
            }
          }
        }
      }

      > .mpsDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 16px;
        width: 100%;
        border-bottom: 1px solid #e9eff0;
        > .mpsPool {
          display: flex;
          flex-direction: row;
          padding: 16px;
          gap: 10px;
          p {
            opacity: 50%;
            margin: 0;
          }
          > .mpImg {
            display: flex;
            > img {
              width: 22px;
              height: 22px;
            }
          }
        }
      }

      > .innerNotesDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        flex-wrap: wrap;
        width: 100%;
        padding: 0 20px 20px 20px;
        gap: 20px;
        > .topLine {
          height: 88.5px;
          p {
            margin-bottom: 0;
            margin-top: 24px;
            font-size: 16px;
            font-weight: 400;
            text-align: center;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          span {
            color: var(--link_color);
            overflow: auto;
            margin-left: 5px;
          }
        }
      }

      > .servicesDiv,
      > .specialtiesDiv {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px 20px 20px 20px;
        border-top: 1px solid #e9eff0;
        > h4 {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 600;
        }
        > .tagsPoolServ {
          margin-top: 10px;
          > span {
            margin: 5px 5px 5px 0;
            padding: 4px 10px;
            border-radius: 8px;
            background: var(--directory_text_background_green);
            color: var(--directory_text_green);
          }
          > p {
            opacity: 50%;
            margin: 0;
          }
        }
        > .tagsPoolSpecs {
          margin-top: 10px;
          > span {
            margin: 5px 5px 5px 0;
            padding: 4px 10px;
            border-radius: 8px;
            background: var(--directory_text_background_blue);
            color: var(--directory_text_blue);
          }
          > p {
            opacity: 50%;
            margin: 0;
          }
        }
      }
    }
  }

  //
  //
  //

  .moreMpsIcon {
    width: 22px;
    height: 22px;
  }

  .hidden {
    visibility: hidden;
    margin-bottom: none;
  }
  .badgeContainer {
    display: flex;
    margin: 12px 0;
  }
}
