.createGroup__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background: rgba(51, 51, 51, 0.4);

  .modal {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    border-radius: 8px;
    width: 600px;
    background-color: #fff;
    padding: 20px 10px 30px 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 15px;

    .modalWrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      .closeBtn {
        cursor: pointer;
        align-self: flex-end;
        margin-bottom: -20px;
      }

      .createModalGrupNameBlock {
        input {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #363636;
        }
      }

      .createModalMembersBlock {
        input {
          &::placeholder {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;

            color: #b1b1b1;
          }
        }

        .members__users {
          border: 1px solid #e6e6e6;
          border-radius: 8px;
          padding: 13px 0 17px 20px;
          height: 80px;
          overflow-y: auto;

          &::-webkit-scrollbar-thumb {
            background-color: #767778;
          }

          &::-webkit-scrollbar-track {
            background-color: #ccc;
            margin: 5px 0;
          }

          &::scrollbar-track {
            background-color: #1c1d1e;
          }

          &::-webkit-scrollbar {
            width: 10px !important;
          }
        }
      }

      .createModalTitle {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
      }

      .createModalLabel {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #363636;
      }

      .createModalBlock {
        display: flex;
        flex-direction: column;
        gap: 10px;

        input {
          border: 1px solid #e6e6e6;
          border-radius: 8px;
          padding: 9px 0 9px 18px;
        }
      }

      .createModalUploadBlock {
        .uploadWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          border: 1px solid #e6e6e6;
          border-radius: 8px;

          padding: 15px 20px;

          .uploadBtns {
            display: flex;
            align-items: center;
            gap: 15px;
          }
        }

        p {
          margin: 0;
          padding: 0;
          margin-top: 8px;
          color: #b1b1b1;
        }
      }

      .createModalLastBlock {
        .lastBlockWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          border: 1px solid #e6e6e6;
          border-radius: 8px;

          padding: 15px 20px;

          .info {
            display: flex;
            align-items: center;
            gap: 20px;

            h3 {
              font-weight: 600;
              font-size: 20px;
              line-height: 27px;
              margin-bottom: 2px;
              color: #363636;
            }

            span {
              font-weight: 400;
              font-size: 20px;
              line-height: 27px;
              color: #363636;
            }
          }
        }
      }

      > button {
        align-self: flex-end;
      }
    }
  }
}
