.btn {
  color: var(--clear_white);
  background: var(--that_blue);

  border: none;
  border-radius: 8px;

  width: 200px;
  height: 40px;
  min-width: fit-content;

  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;

  cursor: pointer;
  user-select: none;

  &:hover:not(:disabled) {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    /* background-color: var(--light_blue_boxes);
		color: var(--that_blue); */
  }

  transition: 300ms;
}

.lineButton {
  font-weight: 400;
  color: var(--text_color);
  background: var(--clear_white);
  background-clip: padding-box;

  border: 0px solid transparent;
  border-radius: 8px;
  position: relative;

  &:before {
    content: '';
    border-radius: inherit;
    transition: 300ms;

    pointer-events: none;
    position: absolute;
    padding: 2px;
    inset: 0;

    background: var(--gradient);

    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: xor;
  }

  &:hover:not(:disabled) {
    opacity: 0.8;

    &:before {
      opacity: 0.6;
    }
  }

  &:disabled {
    opacity: 0.6;

    &:before {
      opacity: 0.4;
    }
  }
}
