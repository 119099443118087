.wrapper {
  height: 80vh;
  flex-direction: row !important;

  > .users {
    width: 30%;
    height: 100%;

    padding: 0 10px;

    // background-color: yellow;

    > .userDialog {
      // border: 1px solid black;

      > span {
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 16px;

        &.active {
          font-weight: 600;
        }
      }

      > .firstname {
        color: red;
      }
      > .separator {
        padding: 0 10px;
        color: green;
      }
      > .username {
        color: blue;
      }
    }
  }

  > .messages {
    width: 70%;
    height: 100%;

    // background-color: red;

    > .header {
      height: 72px;
    }

    > .body {
      height: calc(100% - 72px - 60px);

      > .message {
        width: 50%;
        min-width: 200px;

        border-radius: 8px;
        padding: 10px;

        background-color: red;

        &[alert] {
          background-color: yellow;
        }

        &[left] {
          background-color: grey;
          right: 0;
        }
      }
    }

    > .input {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      height: 60px;
      // background-color: red;

      > input {
        width: 70%;
        height: 100%;

        background: #f2f2f2;
        color: var(--text_color);
        border-radius: 8px;

        border: none;
      }
      > .circleButton {
        width: 30px;
        height: 30px;
        background-color: red;

        border-radius: 50px;
      }
    }
  }
}
