.banNotice {
  > span {
    padding-bottom: 20px;
  }
}

.wrapper {
  > .block:first-child > .line {
    min-height: 100px;

    > .image {
      width: 100px;
      height: 100px;

      display: flex;
      margin-right: 30px;

      > img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }

    > .rest {
      width: calc(100% - 100px - 30px);

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      > .left {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        // width: 100%;

        > * {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: center;

          width: 100%;

          > *:not(:last-child) {
            margin-right: 8px;
          }
        }

        > .names {
          > span {
            font-size: 32px;
            font-weight: 700;

            @media screen and (max-width: 767px) {
              font-size: 25px;
              justify-content: center;
            }
          }

          > span[title] {
            font-weight: 600;
          }
        }

        > .loca {
          > svg {
            width: 22px;
            height: 22px;
          }

          > span {
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
          }
        }
      }

      > .messageButton {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > button {
          width: 160px;
          max-width: 200px;

          margin: 13px;

          > svg {
            margin-right: 10px;
          }
        }

        @media screen and (max-width: 767px) {
          justify-content: center;
          gap: 10px;
          button {
            padding: 20px 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            margin: 0;
          }
        }
      }
    }
  }

  > hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--that_grey);

    margin-top: 10px;
    margin-bottom: 30px;
  }
  > .badgeSection {
    display: flex;
    flex-direction: column;

    > .badgeTitle {
      display: flex;
      flex-direction: row;
      width: 175px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      text-align: center;
      font-family: 'Open Sans';
      font-style: normal;
      color: #363636;
      > h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */
      }
      > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
    > .badgeContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 20px;
      margin-bottom: 30px;
      gap: 50px;
    }
  }
  > .block {
    width: 100%;

    display: flex;
    flex-direction: column;

    margin: 20px 0;

    .line {
      width: 100%;

      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;

      > * {
        margin-right: 10px;
      }

      > *:last-child {
        margin-right: 0;
      }

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .notEnough {
      // > span {}

      > .navigator {
        display: flex;
        flex-direction: column;

        > span {
          margin: 20px 0;
        }
      }
    }

    .brick {
      display: flex;
      flex-direction: column;
    }

    .tag {
      padding: 3px 10px;
      margin-bottom: 4px;
      margin-right: 4px;

      border-radius: 4px;
      background-color: var(--that_lightgrey);
    }

    .imagesPool {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;

      > .imageOne {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 80px;

        margin-top: 10px;
        margin-right: 20px;

        > img {
          width: 50px;
          height: 50px;
        }

        > span {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .placeholderText {
      opacity: 0.8;
    }

    .headline {
      width: 100%;

      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;

      margin-bottom: 10px;

      > * {
        margin-right: 10px;
      }

      > h4 {
        margin-bottom: 0;
      }
    }

    .coloredSpan {
      padding: 2px 10px;
      border-radius: 8px;

      width: auto;
      height: 20px;
    }

    hr {
      width: 100%;
      border: none;
      border-bottom: 1px solid var(--that_grey);

      margin: 0;
      margin-bottom: 20px;
    }
  }

  > .block:last-child {
    > .line,
    > .brick {
      margin-bottom: 20px;
    }

    margin-bottom: 0;

    > *:last-child {
      margin: 0;
    }

    > .line > .brick {
      margin-right: 40px;
    }
  }

  span:empty,
  .tag:empty,
  .line:empty,
  .brick:empty,
  .block:empty {
    display: none;
  }

  //
  //
  //

  h2,
  h4 {
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;

    margin-bottom: 20px;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;

    margin-bottom: 10px;
  }

  span {
    font-size: 16px;
    font-weight: 400;
  }

  pre {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    font-weight: 400;

    text-overflow: clip;
    white-space: pre-wrap;
    overflow: hidden;

    margin: 0;
  }

  //
  //
  //

  // .line,
  // .brick {
  // 	background-color: #ff000010;
  // }

  // > * {
  // 	background-color: #ff00ff10;
  // }
}

.imagesPool {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;

  > .imageOne {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 90px;

    margin-top: 10px;
    margin-right: 10px;

    > img {
      width: 50px;
      height: 50px;
    }

    > span {
      margin-top: 2px;

      font-size: 16px;
      font-weight: 400;

      width: 100%;
      text-align: center;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.keyvaluePool {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;

  border-left: 1px solid var(--that_grey);
  margin-left: 19px;
  padding-left: 20px;

  > .kvwrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    width: 100%;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }

    > .kvkey,
    > .kvPool > .kvvalue {
      height: 30px;
      margin: 5px 10px 5px 0;
    }

    > .kvkey {
      display: flex;
      align-items: center;

      font-size: 16px;
      font-weight: 600;
    }

    > .kvPool {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      > .kvvalue {
        width: auto;

        padding: 0 10px;

        > span {
          font-weight: 400;
        }
      }
    }
  }
}

.newwrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch !important;

  > h2 {
    margin-bottom: 20px;
  }

  .brick {
    display: flex;
    flex-direction: column;

    > .line {
      margin-bottom: 20px;
    }
  }

  .line {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    align-items: center;

    > * {
      margin-right: 10px;
    }
  }

  .halfline {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    > hr {
      margin-top: 10px;
    }

    > .half {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;

      > * {
        margin-bottom: 10px;
        margin-right: 10px;

        text-transform: capitalize;
      }

      > .coloredSpan {
        padding: 2px 10px;
        border-radius: 8px;

        width: auto;
        height: 20px;
      }
    }
  }

  //
  //
  // hr

  > div > hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--that_grey);

    margin-top: 20px;
    margin-bottom: 20px;
  }

  > div:last-child > hr:last-child {
    display: none;
  }

  //
  //
  //

  span:empty,
  .tag:empty,
  .line:empty,
  .brick:empty,
  .block:empty {
    display: none;
  }

  //
  //
  //

  h2,
  h4 {
    text-transform: capitalize;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
  }
  > div > h4 {
    margin-bottom: 10px;
  }

  pre {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    font-weight: 400;

    text-overflow: clip;
    white-space: pre-wrap;
    overflow: hidden;

    margin: 0;
  }
}
