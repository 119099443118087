.card {
  display: flex;
  flex-direction: column;

  padding: 20px;

  width: 280px;
  min-height: 280px;

  border: 1px solid var(--light_grey);
  border-radius: 8px;
  background-color: #fff;
  > .header {
    margin: -20px -20px 0 -20px;
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    > div {
      float: left;
      text-transform: capitalize;
    }
    &.green {
      background-color: var(--light_green);
    }
    &.blue {
      background-color: var(--light_blue);
    }
    &.light_red {
      background-color: var(--light_red);
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    //gap: 10px;
    gap: 20px;
    //position: relative;

    height: 100%;
    > .tags {
      display: flex;
      gap: 5px;
      margin-top: 10px;
      > span {
        background-color: #f6f6f6;
        padding: 5px 8px;
        border-radius: 8px;
      }
    }
    > .title {
      font-size: 20px;
      font-weight: 600;

      text-align: left;
      overflow: hidden;
      gap: unset;

      /* text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
      /* line-clamp: 3;
            -webkit-box-orient: vertical; */

      margin-top: 10px;
      > .title {
        &.active {
          border: 2px solid var(--that_blue);
          border-radius: 4px;
        }
      }
    }
    > .notes {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      gap: unset;

      /* display: -webkit-box;
            -webkit-line-clamp: 3; 
            line-clamp: 3;
            -webkit-box-orient: vertical; */

      color: var(--that_grey);
      > .notes {
        &.active {
          border: 2px solid var(--that_blue);
          border-radius: 4px;
        }
      }
    }
    > .dateAndShare {
      display: flex;
      flex-direction: row;
      gap: 10px;
      //justify-content: center;
      align-items: center;
      > .datepicker {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        z-index: 5;
        > div {
          input {
            padding: 5px;
            border-radius: 8px;
            border: 1px solid var(--that_grey);
            margin-top: 10px;
          }
        }
      }

      //margin-top: auto;
      /* .date{
                width: 100%;
                &.active{
                    border: 2px solid var(--that_blue);
                    border-radius: 4px;
                    >div>div>input{
                        border: none;
                        border-radius: 4px;
                        padding: 5px;
                    }
                }
            } */
    }
    .total {
      &.active {
        border: 2px solid var(--that_blue);
        border-radius: 4px;
      }
      &.error {
        color: red;
      }
    }
    > .progressBar {
      display: flex;
      gap: 20px;

      margin-top: auto;
      > .meter {
        width: 100%;
        height: 6px;
        background: var(--light_grey);
        border-radius: 25px;
        padding: 3px;
        box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
        align-self: center;
        > .bar {
          display: block;
          height: 100%;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          background-color: var(--that_green);
          background-image: linear-gradient(
            center bottom,
            var(--that_green) 37%,
            var(--that_green) 69%
          );
          box-shadow:
            inset 0 2px 9px rgba(255, 255, 255, 0.3),
            inset 0 -2px 6px rgba(0, 0, 0, 0.4);
          position: relative;
          overflow: hidden;
          &.completed {
            background: var(--gradient);
          }
        }
      }
      > .progressStats {
        display: flex;
        flex-direction: row;
        gap: 5px;
      }
    }
    > .txt {
      color: var(--that_green);
      font-size: 14px;
    }
    > .actionsParent {
      position: relative;
      height: 0px;
      width: 100%;
      > .actions {
        position: absolute;
        display: flex;
        gap: 5px;
        flex-direction: column;
        z-index: 1;
        border: 2px solid var(--that_grey);

        background-color: white;
        border-radius: 8px;
        bottom: -70px;
        right: 0;
        > div {
          border-radius: 8px;
          //background-color: white;
          padding: 5px;
          > svg {
            margin-right: 5px;
          }
          &:hover {
            border-radius: 0px;
            background-color: var(--light_blue);
            cursor: pointer;
          }
        }
      }
    }

    > .btnsDropdown {
      position: relative;
      > .fakeBtn {
        width: 200px;
        height: 30px;
      }
    }
    .clickToClose {
      // background-color: red;
      background-color: transparent;
      position: fixed;
      z-index: 0;

      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
  .msg {
    font-size: 14px;
    color: var(--that_red);
    padding-left: 5px;
    text-align: center;
  }
}
.top {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-end;
}
.bottom {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-end;
}
.react-datepicker-popper {
  z-index: 2 !important;
}
