.wrapper {
  & .text {
    margin: 0 0 0 10px;
  }

  & .reason {
    margin: 10px 0 0 20px;
    opacity: 0.8;
  }
}

.btn_back {
  margin-top: 40px;
}
